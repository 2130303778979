// The following sources were taken and adopted from:
// https://github.com/lit/lit/tree/main/packages/lit-html
const textEndRegex = /<(?:(!--|\/[^a-zA-Z])|(\/?[a-zA-Z][^>\s]*)|(\/?$))/g;
const HTML_RESULT = 1;
const SVG_RESULT = 2;
const COMMENT_START = 1;
const TAG_NAME = 2;
const DYNAMIC_TAG_NAME = 3;
const SPACE_CHAR = `[ \t\n\f\r]`;
const ATTR_VALUE_CHAR = `[^ \t\n\f\r"'\`<>=]`;
const NAME_CHAR = `[^\\s"'>=/]`;
const ENTIRE_MATCH = 0;
const ATTRIBUTE_NAME = 1;
const SPACES_AND_EQUALS = 2;
const QUOTE_CHAR = 3;
const comment2EndRegex = />/g;
const commentEndRegex = /-->/g;
const rawTextElement = /^(?:script|style|textarea)$/i;

const tagEndRegex = new RegExp(
    `>|${SPACE_CHAR}(?:(${NAME_CHAR}+)(${SPACE_CHAR}*=${SPACE_CHAR}*(?:${ATTR_VALUE_CHAR}|(["'])|))|$)`,
    'g'
);

const singleQuoteAttrEndRegex = /'/g;
const doubleQuoteAttrEndRegex = /"/g;

const marker = `uibaltic$${String(Math.random()).slice(9)}$`;
const boundAttributeSuffix = '$uibaltic$';

const markerMatch = '?' + marker;
const nodeMarker = `<${markerMatch}>`;

const createTemplate = (html) => {
    const el = document.createElement('template');
    el.innerHTML = html;
    return el;
};

const walker = document.createTreeWalker(
    document,
    129 /* NodeFilter.SHOW_ELEMENT */,
    null
);

/**
 * @param {Array<string>} strings
 * @param {number} type
 * @returns {{tpl: HTMLTemplateElement, attrNames: Array<string>}}
 */
const parseTemplateLiteral = (strings, type) => {
    const l = strings.length - 1;
    const attrNames = [];
    let html = type === SVG_RESULT ? '<svg>' : '';
    let rawTextEndRegex = null;
    let regex = textEndRegex;

    for (let i = 0; i < l; i++) {
        const s = strings[i];
        let attrNameEndIndex = -1;
        let attrName = '';
        let lastIndex = 0;
        let match;
        while (lastIndex < s.length) {
            regex.lastIndex = lastIndex;
            match = regex.exec(s);
            if (match === null) {
                break;
            }
            lastIndex = regex.lastIndex;
            if (regex === textEndRegex) {
                if (match[COMMENT_START] === '!--') {
                    regex = commentEndRegex;
                } else if (match[COMMENT_START] !== undefined) {
                    regex = comment2EndRegex;
                } else if (match[TAG_NAME] !== undefined) {
                    if (rawTextElement.test(match[TAG_NAME])) {
                        rawTextEndRegex = new RegExp(
                            `</${match[TAG_NAME]}`,
                            'g'
                        );
                    }
                    regex = tagEndRegex;
                } else if (match[DYNAMIC_TAG_NAME] !== undefined) {
                    // dynamic tag name
                    regex = tagEndRegex;
                }
            } else if (regex === tagEndRegex) {
                if (match[ENTIRE_MATCH] === '>') {
                    regex = !(
                        rawTextEndRegex === undefined ||
                        rawTextEndRegex === null
                    )
                        ? rawTextEndRegex
                        : textEndRegex;
                    attrNameEndIndex = -1;
                } else if (match[ATTRIBUTE_NAME] === undefined) {
                    attrNameEndIndex = -2;
                } else {
                    attrNameEndIndex =
                        regex.lastIndex - match[SPACES_AND_EQUALS].length;
                    attrName = match[ATTRIBUTE_NAME];
                    regex =
                        match[QUOTE_CHAR] === undefined
                            ? tagEndRegex
                            : match[QUOTE_CHAR] === '"'
                              ? doubleQuoteAttrEndRegex
                              : singleQuoteAttrEndRegex;
                }
            } else if (
                regex === doubleQuoteAttrEndRegex ||
                regex === singleQuoteAttrEndRegex
            ) {
                regex = tagEndRegex;
            } else if (
                regex === commentEndRegex ||
                regex === comment2EndRegex
            ) {
                regex = textEndRegex;
            } else {
                regex = tagEndRegex;
                rawTextEndRegex = undefined;
            }
        }

        const end =
            regex === tagEndRegex && strings[i + 1].startsWith('/>') ? ' ' : '';

        html +=
            regex === textEndRegex
                ? s + nodeMarker
                : attrNameEndIndex >= 0
                  ? (attrNames.push(attrName),
                    s.slice(0, attrNameEndIndex) +
                        boundAttributeSuffix +
                        s.slice(attrNameEndIndex)) +
                    marker +
                    end
                  : s +
                    marker +
                    (attrNameEndIndex === -2
                        ? (attrNames.push(undefined), i)
                        : end);
    }

    const htmlResult =
        html + (strings[l] || '<?>') + (type === SVG_RESULT ? '</svg>' : '');

    const tpl = createTemplate(htmlResult);

    return { tpl, attrNames };
};

export {
    parseTemplateLiteral,
    HTML_RESULT,
    SVG_RESULT,
    marker,
    walker,
    rawTextElement,
    boundAttributeSuffix,
};
