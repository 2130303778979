import { PopoverAlignment, PopoverShifting } from './constants.js';
import { getBaseGridPoint } from './ui-helpers.js';

/**
 * @memberof SharedComponents
 * @class Helpers
 * @alias helpers
 * @classdesc
 * Helpers for commonly used functions.
 */

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @memberof Helpers
 * @property {Function} [cancel] Belays debounce by clearing timeout.
 * @param {Function} func Function that needs to be executed.
 * @param {number} wait Time in milliseconds before function would be executed.
 * @param {boolean} [immediate] Executes function right away if set
 * on 'true', instead of after timeout when set to 'false'.
 * @returns {Function} Debounce result.
 */
const debounce = (func, wait, immediate = false) => {
    let timeout;
    const result = function () {
        const args = arguments;
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(
            function () {
                timeout = null;
                if (!callNow) {
                    func.apply(this, args);
                }
            }.bind(this),
            wait
        );
        if (callNow) {
            func.apply(this, args);
        }
    };
    result.cancel = () => clearTimeout(timeout);
    return result;
};

/**
 * Returns a function, that, as long as it continues to be invoked, will be
 * triggered only once in a defined time. The function will be called
 * periodically every N milliseconds. If `immediate` is passed, trigger
 * the function on the leading edge, instead of the trailing.
 * @memberof Helpers
 * @param {Function} callback Function that needs to be executed.
 * @param {number} delay Time in milliseconds before function would be executed.
 * @param {boolean} [immediate] Executes function right away if set
 * on 'true', instead of after timeout when set to 'false'.
 * @returns {Function} Throttled result.
 */
const throttle = (callback, delay, immediate = false) => {
    let throttled = false;
    return function () {
        const args = arguments;
        if (throttled) {
            return;
        }
        if (immediate) {
            callback.apply(this, args);
        }
        throttled = true;
        setTimeout(
            function () {
                throttled = false;
                if (!immediate) {
                    callback.apply(self, args);
                }
            }.bind(this),
            delay
        );
    };
};

/**
 * Adds leading zero to numbers
 * @memberof Helpers
 * @param {string|number} value
 * @returns {string}
 */
const addLeadingZero = (value) => String(value).replace(/^(-?)(\d)$/, '$10$2');

/**
 * Parses the string in format 'dd.mm.yyyy hh:mm:ss' or for Lithuania
 * 'yyyy-mm-dd hh:mm"ss' and returns the Date.
 * @memberof Helpers
 * @param {string} dateStr
 * @returns {Date|null}
 */
const makeDateFromString = (dateStr) => {
    /** @type {Array<string | number>} */
    let parts;
    const formats = [
        /^(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}):(\d{2})$/,
        /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/,
    ];
    for (let i = 0; i < formats.length; ++i) {
        parts = dateStr.match(formats[i]);
        if (parts) {
            break;
        }
    }

    // if wrong format just return.
    if (!parts) {
        return null;
    }

    // Remove leading zeros
    [1, 2, 4, 5, 6].forEach((i) => {
        parts[i] = Number(parts[i].replace(/^0+/, ''));
    });

    let returnDate;
    if (dateStr.match(formats[1])) {
        returnDate = new Date(
            parts[1],
            --parts[2],
            parts[3],
            parts[4],
            parts[5],
            parts[6]
        );
    } else {
        returnDate = new Date(
            parts[3],
            --parts[2],
            parts[1],
            parts[4],
            parts[5],
            parts[6]
        );
    }

    return returnDate;
};

/**
 * Parses the string in format 'dd.mm.yyyy hh:mm:ss' and returns the Date.
 * @memberof Helpers
 * @param {Date} date
 * @returns {string}
 */
const makeStringFromDate = (date) => {
    const dt = [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    ];

    // Add leading zeros
    [1, 2, 3, 4, 5].forEach((i) => (dt[i] = addLeadingZero(dt[i])));

    return `${dt[2]}.${dt[1]}.${dt[0]} ${dt[3]}:${dt[4]}:${dt[5]}`;
};

/**
 * @callback PopoverHandler
 * @returns {PopoverHandlerResult}
 */

/**
 * @typedef PopoverHandlerResult
 * @property {string} x x coordinates
 * @property {string} y y coordinates
 * @property {string} align align of popover
 * @property {string} shift shift in pixels
 */

/**
 * @typedef IPopoverConfig
 * @property {Function | number} [popoverHeight] Popover height
 * @property {Function | number} [verticalOffset] Vertical offset
 * @property {Function | number} [popoverWidth] Popover width
 * @property {Function | number} [horizontalOffset] Horizontal offset
 * @property {Function | boolean} [invertHorizontalAlignment] Make popover aligned by right side
 * @property {Function | boolean} [supportMiddleAlignment] allow to use middle alignment
 * @property {Function | boolean} [supportTopAlignment] allow to use top alignment
 * @property {Function | boolean} [supportBottomAlignment=true] allow to use bottom alignment
 * @property {Function | boolean} [horizontalAxis] force to use horizontal axis
 * @property {Function | "left" | "right"} [horizontalAxisSide] default side for horizontal axis
 * @property {Function | "top" | "middle" | "bottom"} [verticalAxisSide]
 * Default side for horizontal axis
 */

/**
 * Creates handler for popover
 * @memberof Helpers
 * @param {HTMLElement} element
 * @param {IPopoverConfig} config
 * @returns {PopoverHandler}
 */
const makePopoverHandler = (element, config) => {
    const resolveValue = (value) =>
        typeof value === 'function' ? value() : value;
    const params = Object.assign(
        {
            popoverHeight: 0,
            popoverWidth: 0,
            verticalOffset: 0,
            horizontalOffset: 0,
            invertHorizontalAlignment: false,
            supportMiddleAlignment: true,
            supportTopAlignment: true,
            supportBottomAlignment: true,
            horizontalAxis: false,
            horizontalAxisSide: null,
            verticalAxisSide: null,
        },
        config
    );
    return () => {
        const popoverHeight = resolveValue(params.popoverHeight);
        const verticalOffset = resolveValue(params.verticalOffset);
        const popoverWidth = resolveValue(params.popoverWidth);
        const horizontalOffset = resolveValue(params.horizontalOffset);
        const invertHorizontalAlignment = resolveValue(
            params.invertHorizontalAlignment
        );
        const supportMiddleAlignment = resolveValue(
            params.supportMiddleAlignment
        );
        const supportTopAlignment = resolveValue(params.supportTopAlignment);
        const supportBottomAlignment = resolveValue(
            params.supportBottomAlignment
        );
        const horizontalAxis = resolveValue(params.horizontalAxis);
        const verticalAxisSide = resolveValue(params.verticalAxisSide);
        const horizontalAxisSide = resolveValue(params.horizontalAxisSide);

        const rect = element.getBoundingClientRect();
        const doc = document.documentElement;

        let align;
        let shift = PopoverShifting.None;
        let xStart;
        let xEnd;
        let yStart;
        let yEnd;

        let verticalScrollOffset = doc.scrollTop
            ? doc.scrollTop
            : document.body.scrollTop;
        let horizontalScrollOffset = doc.scrollLeft
            ? doc.scrollLeft
            : document.body.scrollLeft;
        if (isInFixedLayer(element)) {
            verticalScrollOffset = 0;
            horizontalScrollOffset = 0;
        }

        const setVerticalAxisBottomPosition = () => {
            align = PopoverAlignment.Bottom;
            yStart = verticalScrollOffset + rect.bottom + verticalOffset;
            yEnd = yStart + verticalOffset + popoverHeight;
        };

        const setVerticalAxisTopPosition = () => {
            align = PopoverAlignment.Top;
            yStart =
                verticalScrollOffset +
                rect.top -
                verticalOffset -
                popoverHeight;
            yEnd = yStart + verticalOffset + popoverHeight;
        };

        const setVerticalAxisMiddlePosition = () => {
            align = PopoverAlignment.Middle;
            if (popoverHeight + verticalOffset * 2 > window.innerHeight) {
                yStart = verticalOffset + verticalScrollOffset;
            } else {
                yStart =
                    (window.innerHeight - popoverHeight) / 2 +
                    verticalScrollOffset;
            }
        };

        const setVerticalAxisHorizontalPosition = () => {
            if (invertHorizontalAlignment) {
                xEnd = rect.right - horizontalOffset;
                xStart = xEnd - popoverWidth;
                if (xStart < 0) {
                    xStart = 0;
                    shift = PopoverShifting.Right;
                }
            } else {
                xStart = rect.left + horizontalOffset;
                xEnd = xStart + popoverWidth;
                if (window.innerWidth < xEnd) {
                    xStart = xStart - (xEnd - rect.right) - horizontalOffset;
                    shift = PopoverShifting.Left;
                }
            }
        };

        const setHorizontalAxisRightPosition = () => {
            align = PopoverAlignment.Right;
            yStart = verticalScrollOffset + rect.top + verticalOffset;
            yEnd = yStart + verticalOffset + popoverHeight;
            xStart = rect.right + horizontalOffset;
            xEnd = xStart + popoverWidth;
        };

        const setHorizontalAxisLeftPosition = () => {
            align = PopoverAlignment.Left;
            yStart = verticalScrollOffset + rect.top + verticalOffset;
            yEnd = yStart + verticalOffset + popoverHeight;
            xEnd = rect.left - horizontalOffset;
            xStart = xEnd - popoverWidth;
        };

        const isHorizontallyFit = () => {
            return (
                xStart >= horizontalScrollOffset &&
                xEnd <= window.innerWidth + horizontalScrollOffset
            );
        };

        const isVerticallyFit = () => {
            return (
                yStart >= verticalScrollOffset &&
                yEnd <= window.innerHeight + verticalScrollOffset
            );
        };

        if (!horizontalAxis) {
            align = verticalAxisSide || PopoverAlignment.Bottom;
            setVerticalAxisHorizontalPosition();
            if (align === PopoverAlignment.Bottom) {
                setVerticalAxisBottomPosition();
                let match = isVerticallyFit();
                if (!match && supportTopAlignment) {
                    setVerticalAxisTopPosition();
                    match = isVerticallyFit();
                }
                if (!match && supportBottomAlignment) {
                    setVerticalAxisBottomPosition();
                    match = isVerticallyFit();
                }
                if (!match && supportMiddleAlignment) {
                    setVerticalAxisMiddlePosition();
                }
            } else {
                setVerticalAxisTopPosition();
                let match = isVerticallyFit();
                if (!match && supportBottomAlignment) {
                    setVerticalAxisBottomPosition();
                    match = isVerticallyFit();
                }
                if (!match && supportMiddleAlignment) {
                    setVerticalAxisMiddlePosition();
                }
            }
        } else {
            align = horizontalAxisSide || PopoverAlignment.Right;
            if (align === PopoverAlignment.Right) {
                setHorizontalAxisRightPosition();
                if (!isHorizontallyFit()) {
                    setHorizontalAxisLeftPosition();
                }
            } else {
                setHorizontalAxisLeftPosition();
                if (!isHorizontallyFit()) {
                    setHorizontalAxisRightPosition();
                }
            }
        }

        element.setAttribute('data-popover-align', align);

        return {
            x: `${xStart}px`,
            y: `${yStart}px`,
            align,
            shift,
        };
    };
};

/**
 * Handles popover max height on resize.
 * @param {HTMLDivElement} popover
 * @param {number} [vOffset]
 * @returns {Function}
 */
const makePopoverMaxHeightHandler = (popover, vOffset = 0) => {
    const handler = () => {
        if (!popover) {
            return;
        }
        // menu-padding-bottom + menu-padding-top + offset x = 24 + 24 + 16 * 2 = 96
        const maxHeight =
            window.innerHeight -
            window.innerHeight / 4 -
            vOffset -
            getBaseGridPoint() * 8;
        popover.style.maxHeight = `${maxHeight}px`;
    };
    window.addEventListener('resize', handler);
    return handler;
};

/**
 * Processes inline scripts/
 * @param {HTMLScriptElement} originalScript
 * @private
 */
const processInlineScript = (originalScript) => {
    const newScript = document.createElement('script');
    [].forEach.call(originalScript.attributes, (attr) => {
        newScript.setAttribute(attr.name, attr.value);
    });
    newScript.appendChild(document.createTextNode(originalScript.innerHTML));
    originalScript.parentNode.replaceChild(newScript, originalScript);
};

/**
 * Processes external scripts with 'src' attribute.
 * @param {HTMLScriptElement} originalScript
 * @returns {Promise<object>}
 * @private
 */
const processExternalScript = (originalScript) => {
    return new Promise((resolve, reject) => {
        const newScript = document.createElement('script');
        newScript.onload = resolve;
        newScript.onerror = reject;
        [].forEach.call(originalScript.attributes, (attr) => {
            newScript.setAttribute(attr.name, attr.value);
        });
        originalScript.parentNode.replaceChild(newScript, originalScript);
    });
};

/**
 * Rebuilds scripts from &lt;script> tags. Usually used in SPA context.
 * @memberof Helpers
 * @param {HTMLElement|DocumentFragment} content
 * @returns {Promise<void>}
 */
const rebuildScripts = (content) => {
    const scripts = content.querySelectorAll(
        'script[type="text/javascript"],script:not([type])'
    );
    const syncQueue = [];
    const asyncQueue = [];
    scripts.forEach((script) => {
        if (script.hasAttribute('src')) {
            asyncQueue.push(() => processExternalScript(script));
        } else {
            syncQueue.push(() => processInlineScript(script));
        }
    });
    return Promise.all(asyncQueue.map((fn) => fn())).then(() =>
        syncQueue.map((fn) => fn())
    );
};

/**
 * Scrolls window to element with offset. Uses ease function.
 * @memberof Helpers
 * @param {number|HTMLElement} target
 * @param {number} [offset]
 * @returns {Promise<void>}
 */
const scrollTo = (target, offset = 0) => {
    offset = offset || 0;
    const start = window.scrollY;
    const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );
    const bodyTopPosition = document.body.getBoundingClientRect().top;
    const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight;

    let targetOffset;
    if (typeof target === 'number') {
        targetOffset = target;
    } else {
        targetOffset = target.getBoundingClientRect().top - bodyTopPosition;
    }

    const targetOffsetToScroll = Math.round(
        (documentHeight - targetOffset < windowHeight
            ? documentHeight - windowHeight
            : targetOffset) - offset
    );
    const duration = 300;
    const startTime = performance.now();
    const easing = (t) => t * (2 - t);

    return new Promise((resolve) => {
        const scrollProgress = () => {
            const delta = performance.now() - startTime;
            const completed = delta >= duration;
            const progress = completed ? 1 : easing(delta / duration);
            const targetOffset =
                start + (targetOffsetToScroll - start) * progress;
            window.scroll(0, targetOffset);
            if (!completed) {
                requestAnimationFrame(scrollProgress);
            } else {
                resolve();
            }
        };
        scrollProgress();
    });
};

/**
 * Checks if the modal opener is inside fixed container.
 * @memberof Helpers
 * @param {HTMLElement | UIElement | EventTarget} elem
 * @returns {boolean}
 */
const isInFixedLayer = (elem) => {
    if (!elem) {
        return false;
    }
    let po = elem.offsetParent;
    while (po !== null) {
        if (window.getComputedStyle(po).position === 'fixed') {
            return true;
        }
        po = po.offsetParent;
    }
    return false;
};

/**
 * Checks if this is value is a plain object. Helper to avoid code duplication
 * and help to migrate from jQuery.
 * @memberof Helpers
 * @param {object} obj
 * @returns {boolean}
 */
const isPlainObject = (obj) => {
    return (
        obj != null && Object.prototype.toString.call(obj) === '[object Object]'
    );
};

/**
 * Assemble query string from Object, for example, for ibank needs: to send in request body
 * @memberof Helpers
 * @param {object} params
 * @param {boolean} [traditional] - `true' for backwards compatibility.
 * @returns {string}
 */
const buildQueryString = (params, traditional = false) => {
    const paramz = Object.assign({}, params);
    if (traditional) {
        Object.keys(paramz).map((key) => {
            if (paramz[key] === null || paramz[key] === undefined) {
                paramz[key] = '';
            }
        });
    }
    return new URLSearchParams(paramz).toString();
};

/**
 * Function will be invoked in the next frame
 * @memberof Helpers
 * @param {Function} func Function that needs to be executed.
 */
const nextFrame = (func) => {
    new Promise(window.requestAnimationFrame).then(() => func());
};

/**
 * Returns languages codes for given country.
 * @memberof helpers
 * @param {("EE" | "LV" | "LT")} [country]
 * @returns {{short: Array<string>, long: Array<string>}}
 */
const getLanguageForCountry = (country) => {
    const defaultLangs = { short: ['EN', 'RU'], long: ['ENG', 'RUS'] };
    switch (country) {
        case 'EE':
            return {
                short: ['ET'].concat(defaultLangs.short.slice()),
                long: ['EST'].concat(defaultLangs.long.slice()),
            };
        case 'LV':
            return {
                short: ['LV'].concat(defaultLangs.short.slice()),
                long: ['LAT'].concat(defaultLangs.long.slice()),
            };
        case 'LT':
            return {
                short: ['LT'].concat(defaultLangs.short.slice()),
                long: ['LIT'].concat(defaultLangs.long.slice()),
            };
        default:
            return {
                short: ['ET', 'LV', 'LT'].concat(defaultLangs.short.slice()),
                long: ['EST', 'LAT', 'LIT'].concat(defaultLangs.long.slice()),
            };
    }
};

/**
 * Format given number to currency value
 * @memberof Helpers
 * @param {number} value
 * @param {string} [thousandSeparator] -  is used to separate groups of thousands
 * @param {number} [fractionDigits] -  amount of decimals numbers after comma
 * @param {boolean} [removeTrailingZeros] - remove zeroes,
 * when there are only zeroes after decimal point;
 * @returns {string}
 */
const formatCurrencyValue = (
    value,
    thousandSeparator = ' ',
    fractionDigits = 2,
    removeTrailingZeros = false
) => {
    let result = value
        .toFixed(fractionDigits)
        .replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

    if (removeTrailingZeros) {
        result = result.replace(/\.0+$/, '');
    }

    return fractionDigits === 0 ? result.split('.').shift() : result;
};

/**
 * Regular expression to increment indexes for ui-duplicate and
 * other duplicatable items.
 * @type {RegExp}
 */
const INDEX_REGEXP = /\D*$|(\[?)(-?\d+)(\D*)(\[])*$/;

/**
 * Resolves indexes for duplication. User for 'name', 'id', 'for' attributes.
 * Rules for settings index:
 * name="hello" -> name="hello-1"
 * name="hello0" -> name="hello1"
 * name="hello[0]" -> name="hello[1]"
 * @memberof Helpers
 * @param {HTMLElement|UIElement} node
 * @param {string} attrName
 * @param {number|string} index
 */
const duplicateIndexSetter = (node, attrName, index) => {
    const value = node.getAttribute(attrName);
    node.setAttribute(
        attrName,
        value.replace(INDEX_REGEXP, (matches, $1, $2, $3) => {
            // 1. If not numbers found: nothing to increment, just add index to the end.
            // 2. If value equals match then no number suffix found, add index to end.
            // 3. If it has '-' in the beginning the just append it to value.
            // This is useful for attributes 'id' and 'label' also provides
            // backwards compatibility with older solution.
            if (
                !$2 ||
                matches === value ||
                (typeof $2 === 'string' && $2.indexOf('-') === 0)
            ) {
                return matches + '-' + index;
            }

            // Standard set for all other cases.
            return ($1 || '') + index + ($3 || '');
        })
    );
};

/**
 * Resolves video host.
 * @memberof Helpers
 * @param {string} src - Video source URL.
 * @returns {("youtube" | "unknown")}
 */
const resolveVideoHost = (src) => {
    const matches = String(src).match(/(youtube\.com|youtu\.be)/);
    return matches ? 'youtube' : 'unknown';
};

/**
 * Checks for modal-controller existence in DOM. If modal controller
 * doesn't exist then force add it the end of the body.
 * Make sure your component imports modal-controller component.
 * @memberof Helpers
 */
const appendModalControllerIfRequired = () => {
    let modalController = document.querySelector('ui-modal-controller');
    if (!modalController) {
        modalController = document.createElement('ui-modal-controller');
        document.body.appendChild(modalController);
    }
};

/**
 * Gets days count in given year and month.
 * @memberof Helpers
 * @param {number} year
 * @param {number} month
 * @returns {number}
 */
const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

/**
 * Deep freeze of object to avoid change/add/delete object's properties.
 * @memberof Helpers
 * @param {object} obj
 * @returns {Readonly<object>}
 */
const deepFreeze = (obj) => {
    Object.keys(obj).forEach((prop) => {
        if (typeof obj[prop] === 'object') {
            deepFreeze(obj[prop]);
        }
    });
    return Object.freeze(obj);
};

/**
 * Converts array to object, index based on even/odd indexes key-pair.
 * Eg. ['a', 100, 'b', 200] => {a: 100, b: 200}
 * @memberof Helpers
 * @param {Array<any>} a
 * @returns {Record<string|number, any>}
 */
const arrayToObject = (a) => {
    const ret = {};
    let i = 0;
    while (i < a.length) {
        ret[a[i++]] = a[i++];
    }
    return ret;
};

/**
 * Minifies HTML template.
 * @memberof Helpers
 * @param {string} template
 * @returns {string}
 */
const minifyHTML = (template) =>
    template
        .replace(/[\s]?\n[\s]*/g, ' ')
        .replace(/>[\s\t]*</g, '><')
        .trim();

/**
 * Clamp method.
 * @param {number} minimumValue
 * @param {number} value
 * @param {number} maximumValue
 * @returns {number}
 */
const clamp = (minimumValue, value, maximumValue) => {
    return Math.max(Math.min(value, maximumValue), minimumValue);
};

/**
 * Checks if value exists in the object.
 * @memberof Helpers
 * @param {Record<string|any>} object
 * @param {any} value
 * @returns {boolean}
 */
const hasValue = (object, value) => {
    return Object.values(object).find((objectValue) => objectValue === value);
};

/**
 * Wait when animation finished for given element. It takes into account
 * prefers-reduced-motion media query. If motion is reduced and no animation
 * then the promise is resolved immediately.
 * @param {HTMLElement} elem
 * @returns {Promise<Animation[]>}
 */
const waitForAnimations = async (elem) => {
    return Promise.all(
        (elem?.getAnimations() || []).map((animation) => animation.finished)
    );
};

/**
 * Lower cases the first letter in the string.
 * @param {string} string
 * @returns {string}
 */
const lowerCaseFirstLetter = (string) => {
    return string[0].toLowerCase() + string.slice(1);
};

export {
    debounce,
    throttle,
    makeDateFromString,
    makeStringFromDate,
    addLeadingZero,
    makePopoverHandler,
    isInFixedLayer,
    rebuildScripts,
    scrollTo,
    isPlainObject,
    buildQueryString,
    nextFrame,
    formatCurrencyValue,
    getLanguageForCountry,
    duplicateIndexSetter,
    resolveVideoHost,
    appendModalControllerIfRequired,
    getDaysInMonth,
    deepFreeze,
    arrayToObject,
    minifyHTML,
    clamp,
    hasValue,
    waitForAnimations,
    makePopoverMaxHeightHandler,
    lowerCaseFirstLetter,
    INDEX_REGEXP,
};
