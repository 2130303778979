/**
 * @memberof SharedComponents
 * @class Config
 * @alias config
 * @classdesc
 * Provides configuration for components.
 * Default configuration:
 * - Render mode: 'synchronous'
 * - Debug mode: false
 * @example
 * import {configureUIComponents, renderSync, debug} from 'shared-components';
 * configureUIComponents(renderSync(), debug());
 */

/**
 * @typedef {'SYNCHRONOUS'|'ASYNCHRONOUS'} IRenderMode
 */

/**
 * @memberof Config
 * @type {{Synchronous: 'SYNCHRONOUS', Asynchronous: 'ASYNCHRONOUS'}}
 */
const RenderMode = {
    Synchronous: 'SYNCHRONOUS',
    Asynchronous: 'ASYNCHRONOUS',
};

const Config = {
    RenderMode: 'RENDER_MODE',
    DebugMode: 'DEBUG_MODE',
};

/**
 * Stores global configuration for components.
 * @type {Map<string, any>}
 */
const globalConfig = new Map([
    [Config.RenderMode, RenderMode.Synchronous],
    [Config.DebugMode, false],
]);

/**
 * Enables asynchronous rendering for components.
 * @memberof Config
 * @returns {(function(): void)}
 */
const renderAsync = () => {
    return () => setRenderMode(RenderMode.Asynchronous);
};

/**
 * Enables synchronous rendering for components.
 * @memberof Config
 * @returns {(function(): void)}
 */
const renderSync = () => {
    return () => setRenderMode(RenderMode.Synchronous);
};

/**
 * Enables debug mode.
 * @memberof Config
 * @param {boolean} [value]
 * @returns {(function(): void)}
 */
const debug = (value = true) => {
    return () => {
        setDebugMode(value);
    };
};

/**
 * Used for lazy loading components.
 * @memberof Config
 * @param {function(): Promise} loadAction
 * @param {string | Element} [selector]
 * @returns {(function(): void)}
 */
const components = (loadAction, selector) => {
    return () => {
        loadAction().then(() => {
            reportComponents(selector);
        });
    };
};

/**
 * Warns if component did not render.
 * @param {string | Element} [selector]
 */
const reportComponents = (selector) => {
    if (!getDebugMode()) {
        return;
    }
    const rootNode =
        typeof selector === 'string'
            ? document.querySelector(selector)
            : selector || document;
    const tags = [].filter
        .call(rootNode.getElementsByTagName('*'), (tag) =>
            tag.tagName.startsWith('UI-')
        )
        .filter((tag) => !('state' in tag));
    tags.forEach((tag) => {
        const tagName = tag.tagName.toLowerCase();

        console.warn(
            `UI component <${tagName}> not loaded. Load module in the configureUIComponents function.`,
            tag
        );
    });
};

/**
 * Sets debug mode.
 * @memberof Config
 * @param {boolean} value
 */
function setDebugMode(value) {
    globalConfig.set(Config.DebugMode, value);
}

/**
 * Returns true if debug mode is enabled.
 * @memberof Config
 * @returns {boolean}
 */
function getDebugMode() {
    return globalConfig.get(Config.DebugMode);
}

/**
 * Sets components rendering mode.
 * @memberof Config
 * @param {IRenderMode} mode
 */
function setRenderMode(mode) {
    globalConfig.set(Config.RenderMode, mode);
}

/**
 * Returns rendering mode.
 * @memberof Config
 * @returns {IRenderMode}
 */
function getRenderMode() {
    return globalConfig.get(Config.RenderMode);
}

// NOTE: jsdoc doesn't support rest parameters
/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/check-param-names */
/**
 * Provides configuration for components.
 * @memberof Config
 * @param {...Function[]} ...settings
 */
const configureUIComponents = (...settings) => {
    settings.forEach((setting) => setting());
};
/* eslint-enable */

export {
    configureUIComponents,
    renderAsync,
    renderSync,
    debug,
    components,
    getDebugMode,
    setDebugMode,
    setRenderMode,
    getRenderMode,
    RenderMode,
};
