import '../data/ui-data.js';
import { UIElement } from '../ui-element.js';
import { createElement } from '../../global/render-api.js';
import styles from './ui-sprite.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UISprite
 * @element ui-sprite
 * @classdesc Represents a class for <code>ui-sprite</code> element.
 * Technical element to loads sprite-image or other external resources into page.
 * @property {string} src {@attr src} Source URL of resources.
 * @example
 * <ui-sprite src="/assets/my-sprites.svg"></ui-sprite>
 */
class UISprite extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                src: String,
            },
        };
    }

    /**
     * Generate a full prefix
     * @returns {string}
     */
    getGlyphPrefix() {
        return '';
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        const uidata = createElement({
            tagName: 'ui-data',
            attributes: {
                src: this.src,
                type: 'image/xml+svg',
            },
        });
        uidata.get((response) => (this.innerHTML = response));
    }
}

UISprite.defineElement('ui-sprite', styles);
export { UISprite };
