export const UIIconColors = {
    DEFAULT: 'default',
    ORANGE: 'orange',
    ORANGE_TEXT: 'orange-text',
    PINEAPPLE: 'pineapple',
    TURQUOISE: 'turquoise',
    TEAL: 'teal',
    SEAFOAM: 'seafoam',
    LILAC: 'lilac',
    TULIP: 'tulip',
    LAVENDER: 'lavender',
    LISTGREY: 'listgrey',
    GREY: 'grey',
    WHITE: 'white',
    ALERT_RED: 'alert-red',
    ALERT_GREEN: 'alert-green',
    ALERT_BLUE: 'alert-blue',
    ALERT_YELLOW: 'alert-yellow',
    TANGERINE: 'tangerine',
    APRICOT: 'apricot',
    ROSE: 'rose',
    BARK_70: 'bark-70',
    BARK_60: 'bark-60',
    BARK_50: 'bark-50',
    BARK_30: 'bark-30',
    BARK: 'bark' /* should deprecate */,
    MEDIUMBROWN: 'mediumbrown' /* should deprecate */,
    DARKVANILLA: 'darkvanilla' /* should deprecate */,
    SOFTBROWN: 'softbrown' /* should deprecate */,
    LIGHTERBARK: 'lighterbark' /* should deprecate */,
};

const AvatarBackgroundColors = {
    White: UIIconColors.WHITE,
    Rose: UIIconColors.ROSE,
    Orange: UIIconColors.ORANGE,
    Turquoise: UIIconColors.TURQUOISE,
    Lilac: UIIconColors.LILAC,
};

const avatarForegroundColorMap = {
    [AvatarBackgroundColors.White]: [
        UIIconColors.ORANGE,
        UIIconColors.TURQUOISE,
        UIIconColors.LILAC,
    ],
    [AvatarBackgroundColors.Rose]: [UIIconColors.ORANGE],
    [AvatarBackgroundColors.Orange]: [UIIconColors.WHITE],
    [AvatarBackgroundColors.Turquoise]: [UIIconColors.WHITE],
    [AvatarBackgroundColors.Lilac]: [UIIconColors.WHITE],
};
const getWarningMessage = (backgroundColor) => {
    const possibleAvatarBackgroundColors = Object.values(
        AvatarBackgroundColors
    );

    if (
        backgroundColor &&
        possibleAvatarBackgroundColors.includes(backgroundColor)
    ) {
        return `Allowed foreground color${backgroundColor === UIIconColors.WHITE ? 's are' : ' is'}: ${avatarForegroundColorMap[backgroundColor].join(', ')}.`;
    }

    return `Allowed background colors are: ${possibleAvatarBackgroundColors.join(', ')}.`;
};

/**
 * Enforces icon, text and checkmark avatar color palette.
 * @param {UIIconColor} backgroundColor
 * @param {UIIconColor} foregroundColor
 * @returns {{backgroundColor: UIIconColor | undefined, foregroundColor: UIIconColor | undefined, warningMessage: string | undefined}}
 */
export const getColorPalette = (backgroundColor, foregroundColor) => {
    const colorPalette = {};

    switch (backgroundColor) {
        case AvatarBackgroundColors.White:
            if (
                ![
                    UIIconColors.ORANGE,
                    UIIconColors.TURQUOISE,
                    UIIconColors.LILAC,
                ].includes(foregroundColor)
            ) {
                colorPalette.foregroundColor = UIIconColors.ORANGE;
                colorPalette.warningMessage =
                    getWarningMessage(backgroundColor);
            }
            break;
        case AvatarBackgroundColors.Rose:
            if (colorPalette.foregroundColor !== UIIconColors.ORANGE) {
                colorPalette.foregroundColor = UIIconColors.ORANGE;
                colorPalette.warningMessage =
                    getWarningMessage(backgroundColor);
            }
            break;
        case AvatarBackgroundColors.Orange:
        case AvatarBackgroundColors.Turquoise:
        case AvatarBackgroundColors.Lilac:
            if (colorPalette.foregroundColor !== UIIconColors.WHITE) {
                colorPalette.foregroundColor = UIIconColors.WHITE;
                colorPalette.warningMessage =
                    getWarningMessage(backgroundColor);
            }
            break;
        default:
            colorPalette.foregroundColor = UIIconColors.WHITE;
            colorPalette.backgroundColor = UIIconColors.LILAC;
            colorPalette.warningMessage = getWarningMessage();
            break;
    }

    return colorPalette;
};
